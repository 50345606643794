import React, {useState, useRef, useEffect, MutableRefObject} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {IconButton, FormControl, MenuItem, Grid, Input, CircularProgress, TextField, Button} from '@mui/material';
import {Box, Stack, Tooltip, Collapse} from "@mui/material";
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {GridColDef, GridRenderCellParams, GridRowModel} from "@mui/x-data-grid";
import {Add, Route} from "@mui/icons-material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {ReactComponent as PasswordIcon} from "@/assets/icons/registration/password.svg";
import {ReactComponent as CardIcon} from "@/assets/icons/registration/card.svg";
import {ReactComponent as VisibleFaceIcon} from "@/assets/icons/registration/visibleLightFace.svg";
import {ReactComponent as FingerprintIcon} from "@/assets/icons/registration/fingerPrint.svg";
import {ReactComponent as PalmIcon} from "@/assets/icons/registration/palm.svg";
import Dialog from "@/components/dialog"
import AvatarIcon from "@/components/avatar/AvatarIcon";
import CustomDataGrid, {DataGridRef} from '@/components/data-grid/CustomDataGrid';
import CustomRowButton from "@/components/data-grid/CustomRowButton";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import {DMSEndpoints} from "@/services/dms/endpoints";
import DeviceService from "@/services/dms/DeviceService";
import EmployeeService from "@/services/hr/EmployeeService"
import CredentialService from "@/services/cms/CredentialService"
import WebURL from "@/urls";
import DeviceInfoHeader from "./DeviceInfoHeader"
import SnackbarUtil from '@/utils/SnackbarUtil';
import ExtraToolBar from './HelpInformation'
import GenericService from "@/services/GenericService";
import FilterAction from "@/components/actions/Filter";
import {Controller, useForm} from "react-hook-form";
import {object, TypeOf, z} from "zod";

type hoverType = "PINdisplay" | "CARDdisplay" | "FPdisplay" | "VLFdisplay" | "PALMdisplay"

enum registrationType {
  "Common",
  "FingerPrint",
  "Face",
  "Voiceprint",
  "Iris",
  "Retina",
  "Palmprint",
  "Fingervein",
  "Palm",
  "Visible Light Face"
}

//special
enum registrationType {
  "Password" = 102,
  "Card" = "card"
}

const FingerSelect: React.FC<{ cRef: MutableRefObject<any> }> = (props) => {
  const {t} = useTranslation();
  const fingerOptions = [
    {value: '4', option: t('dms.Left Thumb')},
    {value: '3', option: t('dms.Left First Finger')},
    {value: '2', option: t('dms.Left Middle Finger')},
    {value: '1', option: t('dms.Left Ring Finger')},
    {value: '0', option: t('dms.Left Little Finger')},
    {value: '5', option: t('dms.Right Thumb')},
    {value: '6', option: t('dms.Right First Finger')},
    {value: '7', option: t('dms.Right Middle Finger')},
    {value: '8', option: t('dms.Right Ring Finger')},
    {value: '9', option: t('dms.Right Little Finger')},
  ];
  const [selected, setSelected] = useState('4')
  const handleChange = (event: SelectChangeEvent) => {
    setSelected(event.target.value)
    props.cRef.current = (event.target.value)
  }
  return (
    <FormControl fullWidth>
      <Select
        value={selected}
        onChange={handleChange}
      >
        {fingerOptions.map((option, idx) => (
          <MenuItem key={idx} value={option.value}>
            {option.option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const RegistrationRender = (refresh: () => void) => (params: GridRenderCellParams) => {
  const {t} = useTranslation();
  const cRef = useRef('');
  const row = params.row;

  const [hoverDisplay, setHoverDisplay] = React.useState({
    PINdisplay: false,
    CARDdisplay: false,
    FPdisplay: false,
    VLFdisplay: false,
    PALMdisplay: false
  });

  const handleMouseHover = (event: React.MouseEvent<HTMLElement>, type: hoverType) => {
    if (event)
      setHoverDisplay({...hoverDisplay, [type]: true});
  };

  const handleMouseOut = (event: React.MouseEvent<HTMLElement>, type: hoverType) => {
    if (event)
      setHoverDisplay({...hoverDisplay, [type]: false});
  };

  const {PINdisplay, CARDdisplay, FPdisplay, VLFdisplay, PALMdisplay} = hoverDisplay;

  const credRegistration = (type: number | string) => {
    cRef.current = '4' //default finger is left thumb
    const cred_name = typeof (type) === "number" ? registrationType[type] : type
    Dialog.confirm({
      title: t("dms.Remote registration title", {type: cred_name}),
      helpInfo: t("dms.Require complete on device"),
      description:
        type == registrationType.FingerPrint ? t(`dms.Which finger you want to choose?`)
          : t("dms.Are you sure to register type {} remotely?", {type: cred_name}),
      content: type == registrationType.FingerPrint ? <FingerSelect cRef={cRef}/> : "",
      onConfirm: () => {
        DeviceService.remoteRegistration(row.deviceId,
          {
            "enrollType": type,
            "pin": row.personCode,
            "fid": cRef.current || "1",
          }).then((result) => {
          console.log('DeviceService.remoteRegistration: ', result);
          if (result.status < 400) {
            SnackbarUtil.success(t("dms.Command has been sent successfully"), {'autoHideDuration': 5000,})
            setTimeout(function () {
              refresh()
            }, 8000);
          }
        })
      }
    })
  }

  const pwdRegistration = () => {
    Dialog.confirm({
      title: t("dms.Are you sure to register a new password?"),
      description: t("dms.Please set a password"),
      content:
        <Grid>
          <Input type={"password"} onChange={event => cRef.current = event.target.value}/>
          <p style={{fontSize: '0.75rem', color: '#f29548'}}>{t("dms.Set password tip")}</p>
        </Grid>,
      onConfirm: () => {
        if (!cRef.current) { //interface not allow empty pwd
          SnackbarUtil.error(t("dms.Please input a valid password"), {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
          return
        }
        const condition = {
          "personIdOrCode": row.personId,
          "passcode": cRef.current,
          "deviceId": row.deviceId
        }
        CredentialService.setPersonPassword(condition).then((result) => {
          if (result.status < 400)
            SnackbarUtil.success(result.data.message);
          refresh();
        })
      }
    })
  }

  const pwdRegistrationByUpdateEmp = () => {
    Dialog.confirm({
      title: t("dms.Are you sure to register a new password?"),
      description: t("dms.Please set a password"),
      content:
        <Grid>
          <Input type={"password"} onChange={event => cRef.current = event.target.value}/>
          <p style={{fontSize: '0.75rem', color: '#f29548'}}>{t("dms.Set password tip")}</p>
        </Grid>,
      onConfirm: () => {
        if (!cRef.current) {
          //interface not allow empty pwd
          SnackbarUtil.error(t("dms.Please input a valid password"), {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
          return
        }
        EmployeeService.update(row.personId, {"password": cRef.current})
          .then((result) => {
            console.log('DeviceService.pwdRegistration: ', result);
            if (result.status < 400)
              SnackbarUtil.success(t("dms.Password Updated Successfully"));
          })
      }
    })
  }

  const isAllowRegistration = (type: number | string) => {
    if (Number(params.row.status) === 0) { //offline
      SnackbarUtil.error(t("dms.Offline device not allow registration"), {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
      return
    } else {
      if (type === registrationType.Password)
        // return pwdRegistrationByUpdateEmp()
        return pwdRegistration()
      else
        return credRegistration(type)
    }
  }

  return (
    <Stack direction={"row"} spacing={1} py={1}>

      <Tooltip title={t('dms.Register Password')} placement='top'
               sx={{display: params.row.pw_support === 1 ? "block" : "none"}}>
        <IconButton
          id='PasswordIconButton'
          name="PIN"
          disableRipple
          sx={{display: params.row.model === "D3" ? "none" : "",p:0}}
          onMouseEnter={(e) => handleMouseHover(e, 'PINdisplay')}
          onMouseLeave={(e) => handleMouseOut(e, 'PINdisplay')}
          onClick={() => isAllowRegistration(registrationType.Password)}
        >
          <PasswordIcon/>
        </IconButton>
      </Tooltip>
      <Collapse in={PINdisplay} orientation="horizontal">
        <Grid sx={{fontSize: "16px", paddingTop: "8px"}}>
          {params.row.credentialCount.passcode}
        </Grid>
      </Collapse>

      {
        (params.row.card_support === 1) ? (
          <>
            <Tooltip title={t('dms.Register Card')} placement='top'
                sx={{display: params.row.card_support === 1 ? "block" : "none"}}>
              <IconButton sx={{p:0}}
                id='CardIconButton'
                name="Card"
                disableRipple
                onMouseEnter={(e) => handleMouseHover(e, 'CARDdisplay')}
                onMouseLeave={(e) => handleMouseOut(e, 'CARDdisplay')}
                onClick={() => isAllowRegistration(registrationType.Card)}
              >
                <CardIcon/>
              </IconButton>
            </Tooltip>
            <Collapse in={CARDdisplay} orientation="horizontal">
              <Grid sx={{fontSize: "16px", paddingTop: "8px"}}>
                {params.row.credentialCount.card}
              </Grid>
            </Collapse>
          </>
        ) : ("")
      }

      <Tooltip title={t('dms.Register Fingerprint')} placement='top'
               sx={{display: params.row.fp_support === 1 ? "block" : "none"}}>
        <IconButton sx={{p:0}}
          id='FingerprintIconButton'
          name="FP"
          disableRipple
          onMouseEnter={(e) => handleMouseHover(e, 'FPdisplay')}
          onMouseLeave={(e) => handleMouseOut(e, 'FPdisplay')}
          onClick={() => isAllowRegistration(registrationType.FingerPrint)}
        >
          <FingerprintIcon/>
        </IconButton>
      </Tooltip>
      <Collapse in={FPdisplay} orientation="horizontal">
        <Grid sx={{fontSize: "16px", paddingTop: "8px"}}>
          {params.row.credentialCount.fingerPrint}
        </Grid>
      </Collapse>

      <Tooltip title={t('dms.Register Visible Light Face')} placement='top'
               sx={{display: params.row.face_support === 1 ? "block" : "none"}}>
        <IconButton sx={{p:0}}
          id='VisibleFaceIconButton'
          name="VLF"
          disableRipple
          onMouseEnter={(e) => handleMouseHover(e, 'VLFdisplay')}
          onMouseLeave={(e) => handleMouseOut(e, 'VLFdisplay')}
          onClick={() => isAllowRegistration(registrationType["Visible Light Face"])}
        >
          <VisibleFaceIcon/>
        </IconButton>
      </Tooltip>
      <Collapse in={VLFdisplay} orientation="horizontal">
        <Grid sx={{fontSize: "16px", paddingTop: "8px"}}>
          {params.row.credentialCount.visibleLightFace}
        </Grid>
      </Collapse>

      { (params.row.palm_lines_support === 1) ? (
        <>
          <Tooltip title={t('dms.Register Palm')} placement='top'
                  sx={{display: params.row.palm_lines_support === 1 ? "block" : "none"}}>
            <IconButton sx={{p:0}}
              id='PalmIconButton'
              name="Palm"
              disableRipple
              onMouseEnter={(e) => handleMouseHover(e, 'PALMdisplay')}
              onMouseLeave={(e) => handleMouseOut(e, 'PALMdisplay')}
              onClick={() => isAllowRegistration(registrationType["Palm"])}
            >
              <PalmIcon/>
            </IconButton>
          </Tooltip>
          <Collapse in={PALMdisplay} orientation="horizontal">
            <Grid sx={{fontSize: "16px", paddingTop: "8px"}}>
              {params.row.credentialCount.palmPrint}
            </Grid>
          </Collapse>
      </>
      ) : (params.row.palmar_vein_support === 1) ? (
        <>
          <Tooltip title={t('dms.Register Palm')} placement='top'
                  sx={{display: params.row.palmar_vein_support === 1 ? "block" : "none"}}>
            <IconButton sx={{p:0}}
              id='PalmIconButton'
              name="Palm"
              disableRipple
              onMouseEnter={(e) => handleMouseHover(e, 'PALMdisplay')}
              onMouseLeave={(e) => handleMouseOut(e, 'PALMdisplay')}
              onClick={() => isAllowRegistration(registrationType["Palm"])}
            >
              <PalmIcon/>
            </IconButton>
          </Tooltip>
          <Collapse in={PALMdisplay} orientation="horizontal">
            <Grid sx={{fontSize: "16px", paddingTop: "8px"}}>
              {params.row.credentialCount.palm}
            </Grid>
          </Collapse>
      </>
      ) : (params.row.visible_light_palm_support === 1) ? (
        <>
          <Tooltip title={t('dms.Register Palm')} placement='top'
                sx={{display: params.row.visible_light_palm_support === 1 ? "block" : "none"}}>
            <IconButton sx={{p:0}}
              id='PalmIconButton'
              name="Palm"
              disableRipple
              onMouseEnter={(e) => handleMouseHover(e, 'PALMdisplay')}
              onMouseLeave={(e) => handleMouseOut(e, 'PALMdisplay')}
              onClick={() => isAllowRegistration(registrationType["Palm"])}
            >
              <PalmIcon/>
            </IconButton>
        </Tooltip>
        <Collapse in={PALMdisplay} orientation="horizontal">
          <Grid sx={{fontSize: "16px", paddingTop: "8px"}}>
            {params.row.credentialCount.ominpalm}
          </Grid>
        </Collapse>
      </>
      ) : ("")
      }

    </Stack>
  )
}

const RegistrationAction = (refresh: () => void) => (cell: GridRenderCellParams) => {
  const {t} = useTranslation();
  return (
    <Stack direction={"row"}>
      <CustomRowButton insideIcon={DeleteOutlineIcon} tooltipTitle={t("common.Delete")} buttonProps={{
        key: "delete",
        onClick: async () => {
          Dialog.confirm({
            title: t("common.Delete"),
            content: t("dms.Do you want to delete this person from device?"),
            onConfirm: () => {
              DeviceService.deleteDevicePerson(`${cell.row.deviceId}`, {"data": {"personId": `${cell.id}`}})
                .then((result) => {
                  console.log('DeviceService.deleteDevicePerson: ', result);
                  if (result.status < 400) {
                    SnackbarUtil.success(t('dms.Delete person successfully'));
                    refresh();
                  }
                });
            }
          });
        }
      }}/>
    </Stack>
  )
}

const PersonInDevice: React.FC = () => {
  const location = useLocation();
  const state = location.state as { id: string };
  const navigate = useNavigate();
  const {t} = useTranslation();
  const dataGridRef = useRef<DataGridRef>();
  const [refresh, setRefresh] = useState<boolean>(true);
  const [loading, setLoading] = React.useState(false);
  const [isAtt, setIsAtt] = useState<boolean>(false);

  const processRowUpdate = async (newRow: GridRowModel, oldRow: GridRowModel) => {

    if (newRow.role === oldRow.role)
      return oldRow
    try {
      // setLoading(true)
      const response = await CredentialService.updateUserRole(newRow.personId, newRow)
      if(response.data.status < 400) {
        SnackbarUtil.success(t("dms.Role Updated Successfully"));
        dataGridRef?.current?.refresh(true);
        return newRow
      }

    } catch (e) {
      return oldRow
    } finally {
      setLoading(false)
    }
  };

  const sumCredentialCount = (credentialCount?: {}) => {
    if (typeof (credentialCount) === 'undefined')
      return 0
    const countList: any = Object.values(credentialCount)
    if (countList.length > 0)
      return countList.reduce((x: number, y: number) => x + y)
    return 0
  }
  const filterFormValidate = object({
    personCode: z.string().optional(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
  })
  type FilterForm = TypeOf<typeof filterFormValidate>;
  const filterForm = useForm<FilterForm>({
    defaultValues: {personCode: "", firstName: "", lastName: ""}
  });
  const {
    register,
    getValues,
    control,
    handleSubmit,
    formState: {errors},
  } = filterForm;
  const [filterParams, setFilterParams] = useState<Record<any, any>>({});
  const [filterShow, setFilterShow] = useState<boolean>(false);
  const handleShowFilter = () => {
    setFilterShow(!filterShow)
  }

  React.useEffect(() => {
    const refreshPage = () => {
      filterForm.reset()
      setFilterParams({})
      dataGridRef.current?.refresh(true);

    };
    refreshPage();
  }, [!filterShow]);

  const FilterComponent = () => {
    if (!filterShow) {
      return (
        <></>
      )
    }
    const onSubmit = async (formData: any) => {
      setFilterParams(formData)
      if (formData.personCode || formData.firstName || formData.lastName) {
        dataGridRef.current?.refresh(true);
      } else {
        SnackbarUtil.error(t("dms.Enter at least 1 character please"), {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        })
      }
    }

    return (
      <>
        <Grid container={true} component={'form'} onSubmit={handleSubmit(onSubmit)}
          sx={{borderRadius: '8px',
            padding: { xl: '30px', xs: "20px" },
            width: '100%',
            border: '1px solid #DDDDDD',
            backgroundColor:"#FFF"
          }}
          >
          <Grid item xs={12} md={2.4} sx={{p:{md:"0 20px 0 0", xs:"20px 0 0 0"}}}>
            <Box sx={{ width: '100%' }} >
              <Box display={'flex'}>
              <Controller
                control={control}
                name="personCode"
                render={({field}) => (
                  <TextField
                    fullWidth
                    label={t("common.Person ID")}
                    value={field.value}
                    size="small"
                    error={!!errors.personCode}
                    helperText={errors.personCode?.message || ""}
                    inputProps={{
                      maxLength: 20,
                     }}
                    {...register("personCode")}
                  />
                )}
              />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={2.4} sx={{p:{md:"0 20px 0 0", xs:"20px 0 0 0"}}}>
            <Box sx={{ width: '100%' }} >
              <Box display={'flex'}>
              <Controller
                control={control}
                name="firstName"
                render={({field}) => (
                  <TextField
                    fullWidth
                    label={t("common.First name")}
                    value={field.value}
                    size="small"
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message || ""}
                    {...register("firstName")}
                  />
                )}
              />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={2.4} sx={{p:{md:"0 20px 0 0", xs:"20px 0 0 0"}}}>
            <Box sx={{ width: '100%' }} >
              <Box display={'flex'}>
              <Controller
                control={control}
                name="lastName"
                render={({field}) => (
                  <TextField
                    fullWidth
                    label={t("common.Last name")}
                    value={field.value}
                    size="small"
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message || ""}
                    {...register("lastName")}
                  />
                )}
              />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={2.4}
            sx={{
              paddingRight: { md: "20px", xs: "0px" },
            }}
            >
            <Box sx={{ width: '100%' }} >
              <Box display={'flex'}>

              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={2.4}
            sx={{
              paddingLeft: { md: "20px", xs: "0px" },
              mt: { md: "0px", xs: "20px" },
              display: "flex",
              justifyContent: { md: "flex-end", xs: "center" },
            }}
            >
              <Box display={'flex'}>
              <Button
                id='SearchButton'
                variant={"contained"}
                type='submit'
                sx={{
                  marginRight: "20px",
                  width: { xl: '100px', xs: "100px" },
                  height: "40px",
                  }}
              >
              {t("common.Search")}
            </Button>
            <Button
                sx={{
                width: { xl: '100px', xs: "100px" },
                height: "40px",
              }}
              id='ResetButton'
              variant={"outlined"}
              onClick={() => {
                filterForm.reset()
                setFilterParams({})
                dataGridRef.current?.refresh(true);
              }}
              >
              {t("common.Reset")}
            </Button>
            </Box>
          </Grid>
        </Grid>

      </>
    )
  }

  const columns: GridColDef[] = [
    {field: 'personCode', headerName: t("common.Person ID"), type: 'string', minWidth: 180, flex: 0.2},
    {
      field: 'full_name',
      headerName: t("common.Person Name"),
      minWidth: 260,
      flex: 0.3,
      renderCell: (e: GridRenderCellParams) => (
        <Grid style={{display: "flex", alignItems: "center"}}>
          <AvatarIcon name={e.row?.firstName || '-'} profilePhoto={e.row?.profilePhotoURL}/>
          <Box style={{padding: '0 8px'}}>{e.row.firstName} {e.row.lastName}</Box>
        </Grid>
      )
    },
    {
      field: 'role',
      headerName: t("dms.Role in device"),
      minWidth: 180,
      flex: 0.2,
      editable: true,
      // type: 'singleSelect',
      // valueOptions: ['ADMINSTRATOR', 'COMMONUSER'],
      // valueFormatter: (params) => {
      //   if (params.value == 'ADMINSTRATOR') // CMS admin word misspelling
      //     return t('dms.Device role Administrator');
      //   return t('dms.Device role CommonUser');
      // },
      renderCell: (params) => {
        return (
          <Grid style={{display: "flex", alignItems: "center"}}>
            <Select
              style={{padding: '0 50px'}}
              // defaultValue={params.value == 'ADMINSTRATOR' ? "ADMINSTRATOR" : "COMMONUSER"}
              value={params.value !== undefined && params.value == 'ADMINSTRATOR' ? "ADMINSTRATOR" : "COMMONUSER"}
              onChange={(newValue) => {
                const value = newValue.target.value;
                const new_value = {...params.row};
                new_value.role = value;
                processRowUpdate(new_value, params.row)
              }}>
              <MenuItem value={'ADMINSTRATOR'}>{t('dms.Device role Administrator')}</MenuItem>
              <MenuItem value={'COMMONUSER'}>{t('dms.Device role CommonUser')}</MenuItem>
            </Select>
          </Grid>
        )
      }
    },
    {field: 'deviceId', type: 'string', flex: 0.1},
    {
      field: 'registration', headerName: t("dms.Remote registration"), headerAlign: 'center', minWidth: 440, flex: 0.3,
      sortable: false, renderCell: RegistrationRender(() => setRefresh(!refresh))
    },
    {
      field: 'actions',
      headerName: t("common.Action"),
      headerAlign: 'center',
      align: 'center',
      flex: 0.2,
      sortable: false,
      renderCell: RegistrationAction(() => setRefresh(!refresh))
    }
  ]

  useEffect(() => {
    GenericService.retrieve(DMSEndpoints.DMS_DEVICE_URI, state.id || "").then((res) => {
      if (res.data?.category === "att") {
        setIsAtt(true)
      }
    })
    dataGridRef.current?.refresh();
  }, [refresh])

  React.useEffect(() => {

    const refreshPage = () => {
      filterForm.reset()
      setFilterParams({})
      dataGridRef.current?.refresh(true);
    };

    if(filterShow === true) {
      refreshPage();
    }

  }, [filterShow]);

  return (
    <>
      <CustomBreadcrumbs title={t("dms.Person in device")}/>
      <DeviceInfoHeader/>
      <CircularProgress
        sx={{position: "absolute", left: "50%", top: "50%", display: loading ? "block" : "none", zIndex: 1}}/>
      <CustomDataGrid
        uri={`${DMSEndpoints.DMS_DEVICE_PERSON_URI}${state.id || ""}/person_list/`}
        getRowId={(row) => row.personId}
        checkboxSelection={false}
        tableHeight={"690px"}
        ref={dataGridRef}
        columns={columns}
        requestParams={filterParams}
        columnVisibilityModel={{
          deviceId: false
        }}
        isCellEditable={(params) => sumCredentialCount(params.row.credentialCount) > 0} // 该人员有credential才能设置管理员
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        toolbar={{
          title: " ",
          breadcrumbs: [
            {breadcrumbName: t("dms.Person and registration title"), path: ""}
          ],
          actions: isAtt ? [{
            key: 'add', icon: Add, helpTxt: t("dms.Add or sync person"), callback: async () => {
              navigate(WebURL.ATT_DEVICE_PERSONS_ADD, {state: {id: state.id}});
            }
          }] : [],
          children: <ExtraToolBar/>,
          filter: <FilterAction handleShowFilter={handleShowFilter}/>,
          belowChildren: <FilterComponent/>,
        }}
        noResultFound={t("organ.Employee no rows")}
      />
    </>
  )
}
export default PersonInDevice;
